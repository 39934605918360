import React from 'react'

const Header = () => {
  return (
    <header className="py-[3.4rem] text-center">
    <h1 className="text-[2.4rem] font-bold ">نکته</h1>
    <p className="text-[1.4rem] pt-[1.9rem] opacity-50">
        درصورت اعتراض به نمره تصحیح شده با پشتیبانی تماس بگیرید.(یه
        نکته بهتر میخوایم)
    </p>
</header>
  )
}

export default Header