import React, { useContext } from "react";
import Cookies from "js-cookie";


export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            action.token && Cookies.set("token", action.token);
            if (Cookies.get("token"))
                return true;

        case "LOGOUT":
            Cookies.remove("token");
            return false;

        default:
            return false;
    }
};
