import React, { useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../../../../assets/img/logo.png";
import Wallet from "../../../../../assets/img/icons/Wallet-2.svg";
import MenuIcon from "@mui/icons-material/Menu";

import { Link, NavLink, redirect } from "react-router-dom";
import { Button, Divider, Drawer, SwipeableDrawer } from "@mui/material";
import { Box } from "@mui/system";
import { useAuth } from "../../../../../contexts/AuthContext";
import ResRightSidebar from "./ResRightSidebar";
function RightSidebar() {


  const { loginDispatch } = useAuth();

  const logoutFunc = () => {
    loginDispatch({
      type: "LOGOUT",
    });
  };

  var width = window.innerWidth;

  const navLink = [
    { title: "داشبورد کاربر", icon: Wallet, path: "/dashboard" },
    { title: "جلسات", icon: VideocamIcon, path: "/dashboard/classes" },
    { title: "امتحانات", icon: TextSnippetIcon, path: "/dashboard/exams" },
    {
      title: "تکالیف",
      icon: DriveFileRenameOutlineIcon,
      path: "/dashboard/homework",
    },
  ];

  return (
    <>
      {width <= 1080 ? (
        <>
          <ResRightSidebar />
        </>
      ) : (
        <div className="h-auto max-md:hidden">
          <aside
            id="default-sidebar"
            aria-label="Sidebar"
            style={{}}
            className={`h-full sidebarRight text-[#A5B0C2] bg-white transition-transform -translate-x-full sm:translate-x-0  `}
          >
            <div className="sidebarRight-wrapper pr-8 pl-24 ">
              <div className="sidebarRight-container">
                <div className="logo-container pt-[3.27rem]">
                  <Link to="/">
                    <img className="logo" src={Logo}></img>
                  </Link>
                </div>

                <div className="nav-container py-52">
                  <ul className="nav-list flex flex-col gap-y-11 ">
                    {navLink.map((x) => (
                      <li>
                        <NavLink exact to={x.path} className="flex ">
                          {" "}
                          {x.icon === Wallet ? (
                            <img src={x.icon} className="ml-[1.77rem]"></img>
                          ) : (
                            <x.icon className="ml-[1.77rem]" sx={{ color: "#A5B0C4", fontSize: 20 }} />
                          )}
                          {x.title}
                        </NavLink>
                      </li>
                    ))}

                    <li className="border-t-2 pt-[3.8rem]">
                      <a>
                        {" "}
                        <VerifiedUserIcon className="ml-[1.77rem]" sx={{ color: "#A5B0C4", fontSize: 20 }} /> قوانین
                      </a>
                    </li>
                    <li onClick={logoutFunc}>
                      <a className="text-red-500" >
                        {" "}
                        <LogoutRoundedIcon className="ml-[1.77rem]" sx={{ color: "#EF4444", fontSize: 20 }} /> خروج
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        </div>
      )}
    </>
  );
}

export default RightSidebar;
