import React, { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router";
import { useSession } from "../contexts/SessionContext";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { Card } from "../components/shares/card/Card";
import { moment } from "jalali-moment";

const ClassesList = () => {
  const location = useLocation();
  const classCat = location.state;
  console.log(classCat);
  const [call, setCall] = useState();
  const { classesList, setClassesList } = useSession();
  const fetchCategory = useFetch({
    method: "POST",
    url: `classroom/section/`,
    noHeader: false,
    trigger: false,
    data: call,
    setter: setClassesList,
  });
  const formData = new FormData();

  const submit = (classCat) => {
    formData.append("headline", classCat);
    setCall(formData);
    fetchCategory.reFetch();
  };
  const redirect = useNavigate()

  useEffect(() => {
    if (classCat) {
      console.log("classCat=>>>>>>>>>>>>>>>>>", classCat);
      submit(classCat);
    } else {
    redirect("dashboard/classes")
    }
  }, [classCat]);
  useEffect(() => {
    console.log(classesList);
  }, [classesList]);

  return (
    <>
      <div className="classes grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[2.3rem] p-16 h-[82vh] overflow-y-scroll  ">
        {classesList?.map((x, index) => (
          <Link to={x.classroom_id} state={classCat}>
            <Card>
              <div>
                <p className="text-[1.2rem] py-[2.25rem]"> جلسه {index + 1}</p>
                <h2 className="text-[3.2rem] pb-[2.25rem]">{x.ClassroomName}</h2>
                <h4 className="text-[1.6rem] pb-[1.3rem]">{x.classroom_headline}</h4>
                <p className="text-[1.2rem] pb-[2.4rem]">{""}</p>
              </div>
              <div className="border-t-2">
                <div className="flex flex-row justify-evenly py-[1.9rem]">
                  {/* <p>{moment(x.classroom_available_time_start, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY")}</p> */}
                  {x.classroom_presence ? <p className="text-blue">حاضر </p> : <p className="text-red-600">غایب</p>}
                </div>
              </div>
            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default ClassesList;
