import React, { useState } from "react";
import { BtnAlternative } from "../../../../shares/button/Button";
import doc from "../../../../../assets/img/icons/Iconly-Light-Paper.svg";
import docDownload from "../../../../../assets/img/icons/docs-download.svg";
import { Card } from "../../../../shares/card/Card";
import useFetch from "../../../../../hooks/useFetch";

const PreviousHW = () => {


  return (
    <div className="">
      {exams.map((exam) => (
        <Card className="grid grid-rows-1 grid-cols-10 mb-[1.6rem]">
          <div className=" self-center text-[2.4rem] row-span-2 col-span-1 border-l-2 h-full flex items-center justify-center">
            <span>{exam.num}</span>
          </div>
          <div className="col-span-9">
            <div className="flex flex-row justify-between items-center pt-9 pb-8 pl-12 pr-12">
              <div className=" flex flex-row items-center">
                <div className="text-start grow-[15]">
                  <h4 className="text-[1.6rem] ">
                    {exam.session}
                    <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
                      {exam.date}
                    </span>
                  </h4>
                  <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">
                    {exam.subject}
                  </p>
                </div>
              </div>
              <div className="text-[1.4rem] flex flex-row justify-between items-center ">
                <BtnAlternative
                  className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] border-2 ml-[1.55rem]"
                  text={"تکلیف"}
                  icon={doc}
                />
                <BtnAlternative
                  className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] text-blue border-2  ml-[1.55rem]"
                  text={"ریز نمرات"}
                />
                <BtnAlternative
                  className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] text-blue border-2  ml-[2.93rem]"
                  text={"سوال و پاسخ نامه"}
                  icon={docDownload}
                />
                <div className="inline-flex items-center">
                  <span className="text-[1.4rem] opacity-50 pl-[1rem]">
                    نمره
                  </span>
                  <p className="text-[2.4rem] opacity-100 text-blue">
                    {exam.score}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default PreviousHW;

const exams = [

];
