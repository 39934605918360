import React, { useState } from "react";
import { Card } from "../../../../shares/card/Card";
import HW from "../../../../../assets/img/homework.png";
import { LinearProgress } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useFetch from "../../../../../hooks/useFetch";
import { timeDifference } from "../../../../../utils/timeDifference";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function HWCard() {
  const [hwBoxInfo, sethwBoxInfo] = useState();

  const fetchData = useFetch({
    method: "GET",
    url: "dashboard/assignment-box",
    noHeader: false,
    trigger: true,
    setter: sethwBoxInfo,
    errMessage: () => toast.error("مشکلی در دریافت اطلاعات تکالیف پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),
  });
  return (
    <>
      {fetchData.loading ? (
        ""
      ) : (
        <Card className="HW md:px-[3.2rem] md:pt-[2.1rem] md:pb-[3.1rem] max-md:py-[2rem] max-md:px-[1.3rem]">
          <div className="">
            <h4 className="text-right">نمره تکالیف</h4>
          </div>
          <div className="flex justify-evenly pt-[2.1rem]">
            <div className="">
              <img src={HW} className="" />
            </div>
            <div className="w-full md:pr-[2.16rem] md:pl-[3.28rem] text-right">
              <h3 className="text-blue text-[2.4rem] inline">{hwBoxInfo && hwBoxInfo[1].average}</h3>{" "}
              <span className="inline">از 100 نمره</span>
              <LinearProgress variant="determinate" className="mt-[1.99rem]" value={hwBoxInfo && hwBoxInfo[1].average} />
              <div className="flex flex-row justify-between pt-[1.43rem]">
                <div className="text-red-500">
                  <ErrorOutlineIcon /> {hwBoxInfo ? hwBoxInfo[1].assignment_abscent_count : ""} تکلیف بی پاسخ
                </div>
                <Link to={"homework"} className="text-blue">
                  تکمیل <ArrowBackIosNewIcon />
                </Link>
              </div>
            </div>
          </div>
          <div className="py-[1.73rem] ">
            {hwBoxInfo
              ? hwBoxInfo[0].map((x) => (
                  <Card className="flex flex-row justify-between items-center mt-6 px-[2.4rem] py-[1.5rem]  ">
                    <div className="text-start">
                      <h4 className="text-[1.4rem] ">{x.assignment_headline}</h4>
                      <p className="text-[1.2rem] opacity-50">{x.AssignmentName}</p>
                    </div>
                    <div className="text-[1rem] ">
                      <p className="opacity-50">
                        {timeDifference(x.assignment_available_time_end, x.assignment_available_time_start)} روز <br /> فرصت مانده
                      </p>
                    </div>
                  </Card>
                ))
              : ""}
          </div>
        </Card>
      )}
    </>
  );
}

export default HWCard;
