import React, { useState, useRef } from "react";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import { Card } from "../../../../shares/card/Card";
import doc from "../../../../../assets/img/icons/Iconly-Light-Paper.svg";
import moment from "jalali-moment";
import { timeDifference } from "../../../../../utils/timeDifference";
import CheckIcon from '@mui/icons-material/Check';
import useFetch from "../../../../../hooks/useFetch";

const CurrentHW = ({ assignmentInfo }) => {
  const [file, setFile] = useState(null);
  const [id, setId] = useState(null);

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileInput = (id, e) => {
    const file = e.target.files[0];
    setId(id);

    const formData = new FormData();

    formData.append("file", file);
    setFile(formData);
    upload.reFetch();
  };

  const upload = useFetch({
    method: "POST",
    url: `assignment-platform/${id}/upload`,
    noHeader: true,
    trigger: false,
    data: file,
  });

  return (
    <>
      {assignmentInfo?.map((x, index) =>
        x.assignment_finished === false ? (
          <Card className="grid grid-cols-8 mb-5 md:grid-cols-10 justify-between items-center">
            <div className="h-full w-full justify-center items-center border-l-2 border-inherit hidden md:flex">
              <h1 className="text-[2.4rem]">{index + 1}</h1>
            </div>
            <div className="text-start col-span-3 md:col-span-4 pt-9 pb-8 pr-4 md:pr-12">
              <h4 className="text-[1.6rem] flex">
                {`${x.assignment_headline}`}
                <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
                  {moment(x.assignment_available_time_end, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY")}
                </span>
              </h4>
              <p className="text-[1.4rem] pt-[.6rem] opacity-50 "> {x.AssignmentName} </p>
            </div>
            <div className="text-[1.4rem] flex flex-row justify-between items-center col-span-5 pl-4 md:pl-12">
              <p className=" px-[1.45rem] border-inherit text-blue ">
                {timeDifference( assignmentInfo[0].assignment_available_time_end , Date.now())} روز{" "}
                <span className="  opacity-50 text-black ">فرصت باقی مانده</span>
              </p>
              <div className="flex">
                <BtnAlternative
                  url={x.assignment_file}
                  download
                  className="border-[#EAEBED] border-2 py-[1.25rem] px-6 md:px-10 lg:px-[3.3rem] ml-[1.78rem]"
                  text={"تکلیف"}
                  icon={doc}
                />
                <BtnPrimary className="py-[1.2rem] px-4 md:px-7 lg:px-[2.2rem] " text={"ارسال پاسخ"} onClick={handleClick} />
                <input type="file" ref={inputRef} onChange={(e) => handleFileInput(x.score.assignment, e)} style={{ display: 'none' }} />
              </div>
            </div>
          </Card>
        ) : (
         ""
        )
      )}
    </>
  );
};

export default CurrentHW;
