import React from "react";
import Play from "../../../../assets/img/Play.png";
import { Card } from "../../../shares/card/Card";
import { ReactComponent as Line } from "../../../../assets/img/triple-blue-line.svg";
import { ReactComponent as CurlyLine } from "../../../../assets/img/curly-line.svg";

const Courses = () => {
    return (
        <div className="container flex flex-col justify-center items-center mb-[16.2rem]">
            <img src={Play} className="mb-10" />
            <h3 className="text-[4.8rem] font-bold mb-8 relative text-center md:text-right">
                دوره‌های آموزشی
                <span className="absolute -bottom-2 -right-14 hidden md:inline-block">
                    <Line />
                </span>
            </h3>
            <p className="text-[2rem] font-light text-center max-w-[64rem] text-[#2C3146] mb-24 px-4 md:px-0">
                تمامی این دوره ها با دقت و حساسیت برای شما دانش آموزان عزیز تهیه
                شده و در طول اتمام دوره و حتی پس از آن پشتیبانی هر روزه ما را
                همراه خود دارید
            </p>
            <div className="flex gap-x-6 flex-col md:flex-row gap-y-8">
                <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit relative">
                    <h4 className="font-bold text-[2.4rem]">ریاضیات دهم</h4>
                    <p className="text-[1.6rem] text-[#8E9BA9] font-light">
                        سرفصل ‏های تدریس ‏شده برای این پایه منطبق با کتاب ریاضی
                        (1) است و از این حیث تفاوتی میان دانش‏ آموزان رشتۀ تجربی
                        و ریاضی نیست.
                    </p>
                    <hr className="my-12" />

                    <span className="text-[1.6rem]">سرفصل‌ها</span>
                    <ul className="text-[1.6rem] font-light list-disc">
                        <li className="marker:text-blue mr-6">توابع</li>
                        <li className="marker:text-blue mr-6">
                            معادله و نامعادله
                        </li>
                        <li className="marker:text-blue mr-6">الگو و دنباله</li>
                        <li className="marker:text-blue mr-6">مثلثات</li>
                        <li className="marker:text-blue mr-6">
                            توابع گویا و عبارات جبری
                        </li>
                        <li className="marker:text-blue mr-6">
                            معادله درجه دوم
                        </li>
                        <li className="marker:text-blue mr-6">توابع</li>
                        <li className="marker:text-blue mr-6">
                            شمارش بدون شمردن
                        </li>
                        <li className="marker:text-blue mr-6">احتمالات</li>
                        <li className="marker:text-blue mr-6">آمار</li>
                    </ul>
                    <span className="absolute -bottom-10 left-8 md:inline-block hidden">
                        <CurlyLine />
                    </span>
                </Card>
                <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit">
                    <h4 className="font-bold text-[2.4rem]">ریاضیات یازدهم</h4>
                    <p className="text-[1.6rem] text-[#8E9BA9] font-light">
                        سرفصل ‏های تدریس ‏شده برای این پایه منطبق با کتاب ریاضی
                        (1) است و از این حیث تفاوتی میان دانش‏ آموزان رشتۀ تجربی
                        و ریاضی نیست.
                    </p>
                    <hr className="my-12" />

                    <span className="text-[1.6rem]">سرفصل‌ها</span>
                    <ul className="text-[1.6rem] font-light list-disc">
                        <li className="marker:text-blue mr-6">توابع</li>
                        <li className="marker:text-blue mr-6">هندسه مختصاتی</li>
                        <li className="marker:text-blue mr-6">
                            معادلات و نامعادلات گویا و گنگ
                        </li>
                        <li className="marker:text-blue mr-6">
                            معادلات درجه دوم
                        </li>
                        <li className="marker:text-blue mr-6">
                            تصاعد(الگو و دنباله)
                        </li>
                        <li className="marker:text-blue mr-6">
                            قدر مطلق و ویژگی‌های آن
                        </li>
                        <li className="marker:text-blue mr-6">
                            براکت و ویژگی‌های آن
                        </li>
                        <li className="marker:text-blue mr-6">هندسه</li>
                        <li className="marker:text-blue mr-6">
                            توابع نمایی و لگاریتمی
                        </li>
                        <li className="marker:text-blue mr-6">مثلثات</li>
                        <li className="marker:text-blue mr-6">حد</li>
                        <li className="marker:text-blue mr-6">پیوستگی</li>
                        <li className="marker:text-blue mr-6">آنالیز</li>
                        <li className="marker:text-blue mr-6">احتمال</li>
                        <li className="marker:text-blue mr-6">آمار</li>
                    </ul>
                </Card>
                <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit">
                    <h4 className="font-bold text-[2.4rem]">ریاضیات دوازدهم</h4>
                    <p className="text-[1.6rem] text-[#8E9BA9] font-light">
                        سرفصل‏های تدریس ‏شده برای این پایه منطبق با کتب ریاضی
                        (3) ویژۀ رشتۀ تجربی و حسابان (2) ویژۀ رشتۀ ریاضی است.
                        دانش‏ آموزان ریاضی سرفصل ‏های نامشترک نظیر مقاطع مخروطی
                        و احتمال را در کتب دیگر خود می‏خوانند.
                    </p>
                    <hr className="my-12" />

                    <span className="text-[1.6rem]">سرفصل‌ها</span>
                    <ul className="text-[1.6rem] font-light list-disc">
                        <li className="marker:text-blue mr-6">توابع</li>
                        <li className="marker:text-blue mr-6">هندسه مختصاتی</li>
                        <li className="marker:text-blue mr-6">
                            معادلات و نامعادلات گویا و گنگ
                        </li>
                        <li className="marker:text-blue mr-6">
                            معادلات درجه دوم
                        </li>
                        <li className="marker:text-blue mr-6">
                            تصاعد (الگو و دنباله)
                        </li>
                        <li className="marker:text-blue mr-6">
                            قدرمطلق و ویژگی های آن
                        </li>
                        <li className="marker:text-blue mr-6">
                            ابراکت و ویژگی های آن
                        </li>
                        <li className="marker:text-blue mr-6">هندسه</li>
                        <li className="marker:text-blue mr-6">
                            توابع نمایی و لگاریتمی
                        </li>
                        <li className="marker:text-blue mr-6">حد </li>
                        <li className="marker:text-blue mr-6">پیوستگی</li>
                        <li className="marker:text-blue mr-6">آنالیز </li>
                        <li className="marker:text-blue mr-6">احتمال</li>
                        <li className="marker:text-blue mr-6">آمار</li>
                    </ul>
                </Card>
            </div>
        </div>
    );
};

export default Courses;
