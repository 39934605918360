import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ShortInfoCard } from "../../../shares/card/Card";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { Percent, Replay10Outlined, Replay30Outlined } from "@mui/icons-material";
import VideoPlayer from "../../../shares/videoPlayer/VideoPlayer";
import { useSession } from "../../../../contexts/SessionContext";
import { toast } from "react-toastify";
import Loading from "../../../../utils/Loading";

const Session = () => {
  const [progress, setProgress] = useState(0);
  const [session, setSession] = useState();
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [controlBar, setControlBar] = useState(false);
  const [percentPostRes, setPercentPostRes] = useState();
  const [classroom_presence, setPercentPostNum] = useState();

  const [firstSession, setFirstSession] = useState(0);
  const [SecondSession, setSecondSession] = useState(0);
  const [thirdSession, setThirdSession] = useState(0);
  const { contentId } = useSession();
  const contentInfo = session?.Class.content_set;
  const videoRef = useRef(null);
  let previousTime = 0;
  // const [previousTime, setPreviousTime] = useState(0);
  const { Id } = useParams();
  const fetchData = useFetch({
    method: "GET",
    url: `classroom/${Id}/`,
    noHeader: false,
    trigger: true,
    setter: setSession,
    errMessage: () => toast.error("مشکلی در دریافت اطلاعات این کلاس پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),
  });

  const { reFetch } = useFetch({
    method: "POST",
    url: `classroom/${Id}/classpresence/`,
    // noHeader: true,
    trigger: false,
    data: classroom_presence,
  });

  useEffect(() => {
    if (progress % 5 === 0) {
      switch (contentId) {
        case 1:
          setFirstSession(progress);

          break;
        case 2:
          setSecondSession(progress);
          break;
        case 3:
          setThirdSession(progress);

        default:
          break;
      }
      // reFetch();
    }
  }, [progress]);

  useEffect(() => {
    setPercentPostNum([firstSession, SecondSession, thirdSession]);
  }, [firstSession, SecondSession, thirdSession]);

  useEffect(() => {
    reFetch();
  }, [classroom_presence]);
  // console.log("mahaaaaaaaaaaaaaaaam", session ? Number.parseInt(session.Presence.classroom_presence_percentage) : "");

  return (
    // <div className="pt-20">
    <>
      <div className="mb-9 pt-20 max-md:text-center max-md:text-base max-md:text-blue">
        <Link to="/dashboard/classes">
          {window.innerWidth < 764 ? <ArrowForwardIosIcon fontSize="inherit" /> : <ArrowForwardIosIcon />}
          لیست جلسات
        </Link>
      </div>
      {fetchData.loading ? (
        <Loading />
      ) : (
        <div className="flex h-[82vh] flex-col">
          {contentId ? (
            <>
              <h4 className="text-[2.4rem] font-bold text-center">
                {contentInfo?.filter((x) => x.id === contentId).map((x) => x.content_name)}
              </h4>

              <div className="flex justify-between items-center whitespace-nowrap max-md:text-[10px] max-md:flex-col max-md:m-5 max-md:gap-5 ">
                <span className=" text-[#8E9BA9] md:hidden">سرفصل: {session?.Class.classroom_headline}</span>
                <ShortInfoCard
                  firstTxt={`جلسه ${
                    contentId
                      ? contentId && contentInfo?.filter((x) => x.id === contentId).map((x) => x.content_order)
                      : contentInfo
                      ? contentInfo?.filter((x, index) => x.content_order === 1).map((x) => x.content_order)
                      : ""
                  }`}
                  secondTxt={`${progress ? progress : "0"}% حضور`}
                  color="text-blue"
                />
                <span className=" text-[#8E9BA9] max-md:hidden">سرفصل: {session?.Class.classroom_headline}</span>
                <ShortInfoCard
                  firstTxt={
                    session ? moment(session.Class.classroom_creation_time, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY") : "نامشخص"
                  }
                  secondTxt={duration ? duration : ""}
                  color="text-blue"
                />
              </div>
              <div className="flex justify-center mt-10">
                {contentId &&
                  contentInfo
                    ?.filter((x) => x.id === contentId)
                    .map((x) => (
                      <VideoPlayer
                        video={x.content_url1}
                        presence_percentage={session && Number.parseInt(session.Presence.classroom_presence_percentage)}
                        percentSetter={setProgress}
                        durationSetter={setDuration}
                      />
                    ))}
              </div>
            </>
          ) : contentInfo ? (
            <>
              {contentInfo
                .filter((x, index) => x.content_order === 1)
                .map((x) => (
                  <div className="flex flex-col">
                    <h4 className="text-[2.4rem] font-bold text-center">{x.content_name}</h4>

                    <div className="flex justify-between items-center">
                      <ShortInfoCard
                        firstTxt={`جلسه ${
                          contentId
                            ? contentId && contentInfo.filter((x) => x.id === contentId).map((x) => x.content_order)
                            : contentInfo
                            ? contentInfo.filter((x, index) => x.content_order === 1).map((x) => x.content_order)
                            : ""
                        }`}
                        secondTxt={`${progress ? progress : "0"}% حضور`}
                        color="text-blue"
                      />
                      <span className=" text-[#8E9BA9]">سرفصل: {session?.Class.classroom_headline}</span>
                      <ShortInfoCard
                        firstTxt={
                          session
                            ? moment(session.Class.classroom_creation_time, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY")
                            : "نامشخص"
                        }
                        secondTxt={duration ? duration : ""}
                        color="text-blue"
                      />
                    </div>
                    <VideoPlayer
                      video={x.content_url1}
                      percentSetter={setProgress}
                      presence_percentage={session && Number.parseInt(session.Presence.classroom_presence_percentage)}
                      durationSetter={setDuration}
                    />
                  </div>
                ))}
            </>
          ) : (
            "مشکلی در دریافت جلسه پیش آمده"
          )}
        </div>
      )}
    </>
    // </div>
  );
};

export default Session;

// <>
// <div className="mb-9">
//   <Link to="/dashboard/classes">
//     <ArrowForwardIosIcon />
//     لیست جلسات
//   </Link>
// </div>
// <div className="flex flex-col">

//   <h4 className="text-[2.4rem] font-bold text-center">{session?.Class.ClassroomName}</h4>
//   <div className="flex justify-between items-center">
//     <ShortInfoCard
//       firstTxt={`جلسه ${
//         contentId
//           ? contentId && contentInfo.filter((x) => x.id === contentId).map((x) => x.content_order)
//           : contentInfo
//           ? contentInfo.filter((x, index) => x.content_order === 1).map((x) => x.content_order)
//           : ""
//       }`}
//       secondTxt={`${progress ? progress : "0"}% حضور`}
//       color="text-blue"
//     />
//     <span className=" text-[#8E9BA9]">سرفصل: {session?.Class.classroom_headline}</span>
//     <ShortInfoCard
//       firstTxt={
//         session ? moment(session.Class.classroom_creation_time, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY") : "نامشخص"
//       }
//       secondTxt={duration ? duration : ""}
//       color="text-blue"
//     />
//   </div>
//   {contentId ? (
//     <div className="flex justify-center mt-10">
//       {contentId &&
//         contentInfo
//           .filter((x) => x.id === contentId)
//           .map((x) => <VideoPlayer video={x.content_url1} percentSetter={setProgress} durationSetter={setDuration} />)}
//     </div>
//   ) : contentInfo ? (
//     <div className="flex justify-center mt-10">
//       {contentInfo
//         .filter((x, index) => x.content_order === 1)
//         .map((x) => (
//           <VideoPlayer video={x.content_url1} percentSetter={setProgress} durationSetter={setDuration} />
//         ))}
//     </div>
//   ) : (
//     "مشکلی در دریافت جلسه پیش آمده"
//   )}
// </div>

// </>
