import React, { Fragment, useState } from "react";
import LeftSidebar from "../../components/layouts/dashboard/Sidebars/leftSidebar/LeftSidebar";
import RightSidebar from "../../components/layouts/dashboard/Sidebars/rightSidebar/RightSidebar";
import HomeDash from "../../components/layouts/dashboard/homeDash/Index";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import { SessionProvider } from "../../contexts/SessionContext";
import useFetch from "../../hooks/useFetch";
function Dashboard() {

  const { Id } = useParams();

  const {pathname}= useLocation()
console.log(pathname)
  return (
    <SessionProvider>
      <div className="dashboard text-2xl flex justify-between ">
        <RightSidebar />
        <div className="main-wrapper pb-[3rem] max-md:w-[100%] w-[72%] md:pr-12">
          <div className="container">
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
        {pathname===`/dashboard/classes/${Id}` ? <LeftSidebar session /> : <LeftSidebar session={false} />}
      </div>
    </SessionProvider>
  );
}

export default Dashboard;
