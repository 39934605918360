import React, { useEffect, useState } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { BtnAlternative } from "../../../../../components/shares/button/Button";
import Result from "../../../../../assets/img/icons/Docs.svg";
import Play from "../../../../../assets/img/icons/Iconly-Light-Play.svg";
import Edit from "../../../../../assets/img/icons/Iconly-Light-Edit.svg";
import { Card } from "./../../../../shares/card/Card";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSession } from "../../../../../contexts/SessionContext";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";

function LeftSidebar({ session }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { contentId, setContentId } = useSession();
  const [sessionId, setSessionId] = useState();
  const { videoSelect, setVideoSelect } = useSession();

  const { Id } = useParams();
  const fetchData = useFetch({
    method: "GET",
    url: `classroom/${Id}/`,
    noHeader: false,
    trigger: true,
    // errMessage: () => toast.error("مشکلی در دریافت اطلاعات قسمت ها پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),
    setter: setSessionId,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { pathname } = useLocation();

  return (
    <aside className={`sidebarLeft pt-[3.6rem] ${pathname === `/dashboard/classes/${Id}` ? "max-md:w-52" : "max-md:hidden"} `}>
      <div className="sidebarLeft-wrapper">
        <div className="sidebarLeft-container flex flex-col items-center max-md:p-0  px-[4.7rem] gap-y-6">
          <div className="events max-md:text-[12px] text-[16px]  pb-[3.4rem]">
            <NotificationsOutlinedIcon fontSize="medium" />
            رویدادها
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ fontSize: "1.6rem", fontFamily: "inherit" }}
            >
              <MenuItem>
                <Link className="text-[1.2rem]"> Profile</Link>
              </MenuItem>
              <MenuItem>
                <Link className="text-[1.2rem]"> My account</Link>
              </MenuItem>
              <MenuItem>
                <Link className="text-[1.2rem]"> Logout</Link>
              </MenuItem>
            </Menu> */}
          </div>
          {pathname === `/dashboard/classes/${Id}`
            ? sessionId?.Class.content_set.slice(0, 3).map((x) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(x.id);
                    // setVideoSelect(sessionId.Class.content_set[x.id]);
                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{x.content_order}</h1>
                  </Card>
                </div>
              ))
            : cardArray.map((x) => (
                <Card className="px-[1.6rem] pt-[2.4rem] pb-[1.6rem]">
                  <h1 className="text-[16px] pb-[1.2rem]">{x.name}</h1>
                  <h2 className="text-[12px] pb-[1.8rem]">{x.name}</h2>
                  <BtnAlternative
                    className="border-[#EAEBED] text-blue border-2 py-4 px-[5.25rem]"
                    url={x.path}
                    icon={x.icon}
                    text={x.btnTxt}
                  />
                </Card>
              ))}
          {/* <p className="opacity-40 pt-6">آخرین بازدید : 20شهریور</p> */}
        </div>
      </div>
    </aside>
  );
}

export default LeftSidebar;

const cardArray = [
  {
    id: 1,
    name: "نتیجه امتحان",
    icon: Result,
    btnTxt: "نتیجه",
    path: "/dashboard/exams",
  },
  {
    id: 2,
    name: "جلسه جدید",
    icon: Play,
    btnTxt: "تماشا",
    path: "/dashboard/classes",
  },
  {
    id: 3,
    name: "تکلیف جدید",
    icon: Edit,
    btnTxt: "انجام",
    path: "/dashboard/homework",
  },
];
