import React from 'react'
import moment from "jalali-moment";
import docDownload from "../../../../../assets/img/icons/docs-download.svg"
import examCheck from "../../../../../assets/img/icons/docs-check.svg"
import { Card } from '../../../../shares/card/Card'
import { BtnAlternative } from '../../../../shares/button/Button'

const PreviousExam = ({ exam }) => {
    return (
        <div className="">
            {exam &&
                <Card className="grid grid-rows-3 md:grid-rows-2 grid-cols-10 mb-[1.6rem]">
                    <div className=" self-center text-[2.4rem] row-span-2 col-span-1 border-l-2 h-full items-center justify-center hidden lg:flex">
                        <span>{exam?.score.id}</span>
                    </div>
                    <div className="lg:col-span-9 col-span-10">
                        <div className="flex flex-row justify-between items-center pt-9 pb-8 pl-12 pr-12">
                            <div className=" flex flex-row items-center">
                                <div className="text-start grow-[15]">
                                    <h4 className="text-[1.6rem] ">
                                        {exam?.name}
                                        <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
                                            {moment(exam.exam_available_time_start, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}
                                        </span>
                                    </h4>
                                    <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">
                                        {exam?.exam_headline}
                                    </p>
                                </div>
                            </div>
                            <div className="text-[1.4rem] flex sm:flex-row justify-between items-center flex-col gap-y-2">
                                <BtnAlternative
                                    className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] border-2 ml-[1.55rem] w-full"
                                    text={"برگه تصحیح شده"}
                                    icon={examCheck}

                                />
                                <BtnAlternative
                                    className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] text-blue border-2  ml-[2.93rem] w-full"
                                    text={"سوال و پاسخ نامه"}
                                    icon={docDownload}
                                />
                                <div className="items-center md:row-auto hidden lg:inline-flex">
                                    <span className="text-[1.4rem] opacity-50 pl-[1rem]">
                                        نمره
                                    </span>
                                    <p className="text-[2.4rem] opacity-100 text-blue">
                                        {exam?.score.score}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items-center md:row-auto inline-flex lg:hidden col-span-10 justify-self-center">
                        <span className="text-[1.4rem] opacity-50 pl-[1rem]">
                            نمره
                        </span>
                        <p className="text-[2.4rem] opacity-100 text-blue">
                            {exam?.score.score}
                        </p>
                    </div>
                    <div className="flex flex-row justify-around text-[1.2rem] font-light opacity-50  col-span-9 items-center">
                        <div className="w-full border-t-2 border-l-2 h-full flex justify-center items-center">
                            <span>{exam?.score.returns_count}جواب درست</span>
                        </div>
                        <div className="w-full border-t-2 border-l-2 h-full flex justify-center items-center">
                            {exam?.score.wrong_counts}جواب غلط
                        </div>
                        <div className="w-full border-t-2 h-full flex justify-center items-center">
                            {exam?.score.none_counts}سوال بی پاسخ
                        </div>
                    </div>
                </Card>
            }
        </div>
    )
}

export default PreviousExam

const exams = [
    {
        num: "1",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "2",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "3",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "4",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "5",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "6",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
    {
        num: "7",
        session: "31 امتحان جلسه",
        subject: "معادلات و نامعادلات گویا",
        date: "7 شهریور",
        score: "80",
        true: "3",
        false: "2",
        blank: "3",
    },
];
