import Wallet from "../../../../../assets/img/icons/Wallet-2.svg";
import React, { useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../../../../assets/img/logo.png";
import { Button, Divider, Drawer, SwipeableDrawer } from "@mui/material";
import { useAuth } from "../../../../../contexts/AuthContext";

const ResRightSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { loginDispatch } = useAuth();
  
  const logoutFunc = () => {
    loginDispatch({
      type: "LOGOUT",
    });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsOpen(!isOpen);
  };
  var anchor = "right";
  const navLink = [
    { title: "داشبورد کاربر", icon: Wallet, path: "/dashboard" },
    { title: "جلسات", icon: VideocamIcon, path: "/dashboard/classes" },
    { title: "امتحانات", icon: TextSnippetIcon, path: "/dashboard/exams" },
    {
      title: "تکالیف",
      icon: DriveFileRenameOutlineIcon,
      path: "/dashboard/homework",
    },
  ];

  return (
    <>
      <div className="z-50">
        <Button
          sx={{ position: "fixed", justifyContent: "flex-start" }}
          onClick={toggleDrawer(true)}
          className={`   ${isOpen ? "" : "hidden"}`}
          color="inherit"
        >
          <WidgetsIcon sx={{ fontSize: "4rem", marginTop: "3rem" }} color="primary" />
        </Button>
      </div>
      <Box
        id="sidebar"
        className={`${isOpen ? "" : "hidden"}`}
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <SwipeableDrawer anchor={"right"} open={isOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
          <aside
            id="default-sidebar"
            aria-label="Sidebar"
            style={{}}
            className={`h-full sidebarRight text-[#A5B0C2] bg-white text-[1.5rem] `}
          >
            <div className="sidebarRight-wrapper pr-8 pl-24">
              <div className="sidebarRight-container">
                <div className="logo-container pt-[3.27rem]">
                  <img className="logo" src={Logo}></img>
                </div>
                
                <div className="nav-container py-52">
                  <ul className="nav-list flex flex-col gap-y-11 ">
                    {navLink.map((x) => (
                      <li>
                        <NavLink exact to={x.path} className="flex ">
                          {" "}
                          {x.icon === Wallet ? (
                            <img src={x.icon} className="ml-[1.77rem]"></img>
                          ) : (
                            <x.icon className="ml-[1.77rem]" sx={{ color: "#A5B0C4", fontSize: 20 }} />
                          )}
                          {x.title}
                        </NavLink>
                      </li>
                    ))}

                    <li className="border-t-2 pt-[3.8rem]">
                      <a>
                        {" "}
                        <VerifiedUserIcon className="ml-[1.77rem]" sx={{ color: "#A5B0C4", fontSize: 20 }} /> قوانین
                      </a>
                    </li>
                    <li>
                      <a onClick={logoutFunc} className="text-red-500" href="">
                        {" "}
                        <LogoutRoundedIcon className="ml-[1.77rem]  " sx={{ color: "#EF4444", fontSize: 20 }} /> خروج
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        </SwipeableDrawer>
      </Box>
    </>
  );
};

export default ResRightSidebar;
