import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
// import video from "../../../assets/vid/Sirvan Khosravi - Bargard - Live in Tehran - 2019 - 4K.mkv";
import ControlIcons from "./ControlIcons";
import screenfull from "screenfull";

const VideoPlayer = ({ percentSetter, durationSetter, video, presence_percentage }) => {
  const [playerstate, setPlayerState] = useState({
    playing: false,
    mute: true,
    volume: 0,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  });
  const [isDuration, setDuration] = useState();
  const [percent, setPercent] = useState();
  const [isReady, setIsReady] = useState(false);

  const playerRef = useRef();
  const { playing, mute, volume, playerbackRate, played, seeking } = playerstate;
  const handlePlayAndPause = () => {
    setPlayerState({
      ...playerstate,
      playing: !playerstate.playing,
    });
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };
  // const handlePlayerSeek = (newValue) => {
  //   setPlayerState({ ...playerstate, played: Math.round(newValue / 100) });
  //   playerRef.current.seekTo(Math.round(newValue / 100));
  // };

  const handlePlayerMouseSeekUp = (newValue) => {
    setPlayerState({ ...playerstate, seeking: false });
    playerRef.current.seekTo(newValue / 100);
  };
  const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const movieDuration = playerRef.current ? playerRef.current.getDuration() : "00:00";
  const format = (seconds) => {
    if (isNaN(seconds)) {
      return "00:00";
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    } else {
      return `${mm}:${ss}`;
    }
  };
  // useEffect(() => {

  //   playerRef.current.seekTo(100);

  // }, [])
  // console.log(movieDuration);
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);
  const handleMuting = () => {
    setPlayerState({ ...playerstate, muted: !playerstate.muted });
  };
  const handleVolumeChange = (e, newValue) => {
    setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), mute: newValue === 0 ? true : false });
  };

  //function for the `onChangeCommitted` event
  const handleVolumeSeek = (e, newValue) => {
    setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), mute: newValue === 0 ? true : false });
  };
  const handlePlayerRate = (rate) => {
    setPlayerState({ ...playerstate, playerbackRate: rate });
    console.log(playerstate);
  };
  //   const playerDivRef = useRef(null);
  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current);
  };
  const duration = movieDuration;
  const currentTime = currentPlayerTime;
  durationSetter(fullMovieTime);
  const handlePlayerProgress = (state) => {
    if (isNaN(duration))
      // duration is NotaNumber at Beginning.
      return;
    setDuration(duration);
    setPercent(Math.round((currentTime / duration) * 100));
    percentSetter(percent);
    // console.log("onProgress", state);
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...state });
    }
    // console.log("player state", playerstate);
    // console.log("afterProgress", state);
    if (percent === 10) {
    }
  };
  const playerDivRef = useRef();
  const onReady = React.useCallback(() => {
    console.log(presence_percentage)
    if (!isReady) {
      playerRef.current.seekTo((presence_percentage * playerRef.current.getDuration()) / 100);
      setIsReady(true);
    }
  }, [isReady]);
  return (
    <>
      <div className="player-wrapper max-md:w-full relative px-10 w-10/12" ref={playerDivRef}>
        <ReactPlayer
          width="100%"
          height="100%"
          controls
          ref={playerRef}
          playing={playing}
          onProgress={handlePlayerProgress}
          url={video}
          volume={volume}
          muted={mute}
          onSeek={seeking}
          playbackRate={playerbackRate}
          onClickPreview={handlePlayAndPause}
          // onReady={playerRef.current ? onReady() : ""}
          onReady={onReady}
        />
        <ControlIcons
          playandpause={handlePlayAndPause}
          playing={playing}
          rewind={handleRewind}
          played={played}
          // onSeek={handlePlayerSeek}
          onSeekMouseUp={handlePlayerMouseSeekUp}
          playedTime={playedTime}
          fullMovieTime={fullMovieTime}
          muting={handleMuting}
          muted={mute}
          volume={volume}
          volumeChange={handleVolumeChange}
          volumeSeek={handleVolumeSeek}
          playerbackRate={playerbackRate}
          playRate={handlePlayerRate}
          fullScreenMode={handleFullScreenMode}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
