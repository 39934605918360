import React, { useEffect } from "react";
import { Routes, Route, useLocation, redirect, Navigate, useNavigate, useParams } from "react-router-dom";
import Dashboard from "./../layout/dashboard/Dashboard";
import Index from "./../components/layouts/dashboard/homeDash/Index";
import ExamDash from "./../components/layouts/dashboard/examDash/Index";
import ClassDash from "../components/layouts/dashboard/ClassesDash/Index";
import Session from "../components/layouts/dashboard/sessionDash/Session";
import HomeWork from "../components/layouts/dashboard/HWDash/Index";
import { useAuth } from "../contexts/AuthContext";
import Cookies from "js-cookie";
import Exam from "../components/layouts/dashboard/examDash/Exam";
import Home from "../layout/Home/Home";
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group";
import ClassesList from "./ClassesList";

function Router(props) {
  const location = useLocation();
  const { isLogin } = useAuth();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  useEffect(() => {
    if (!token) navigate("/");
  }, [token]);
  const classCat = location.state;
  console.log(classCat);
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <div className="content h-full">
          <Routes location={location}>
            <Route key={"/"} path="/" element={<Home />} />
            <Route key={"/dashboard"} path="/dashboard" element={<Dashboard />}>
              <Route key={""} path="" element={<Index />} index />
              <Route key={"exams"} path="exams" element={<ExamDash />} />
              <Route key={"classes"} path="classes" element={<ClassDash />} />
              <Route key={"homework"} path="homework" element={<HomeWork />} />
              <Route key={"classes/:Id/"} path="classes/:Id" element={<ClassesList />} />
              <Route key={`classes/:Id/:Id`} path="classes/:Id/:Id" element={<Session />} />
            </Route>
            <Route key={"/dashboard/exam/:Id"} path="/dashboard/exam/:Id" element={<Exam />} />
          </Routes>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default Router;
