import React, { createContext, useReducer, useState, useContext } from "react";
import { authReducer } from "../reducers/AuthReducer"
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = (props) => {
    const [isLogin, loginDispatch] = useReducer(authReducer);


    return (
        <AuthContext.Provider 
            value={{
                isLogin, loginDispatch
            }}
            {...props}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
