import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./contexts/AuthContext";
import Router from "./routes/Router";
import "./styles/main.scss";
import { ThemeProvider, createMuiTheme } from "@mui/material";

function App() {
  const THEME = createMuiTheme({
    typography: {
      fontFamily: `"Sans-Mobile", "sans-serif"`,
      fontSize: 20,
    },

  });
  return (
    <>
      <ToastContainer limit={3} />
      <AuthProvider>
        <div className="app">
          <ThemeProvider theme={THEME}>
            <Router basename />
          </ThemeProvider>
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
