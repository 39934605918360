import React, { createContext, useContext } from "react";
import { useState } from "react";

const SessionContext = createContext();
const SessionProvider = (props) => {
  const [contentId, setContentId] = useState(1);
  const [videoSelect, setVideoSelect] = useState();
  // const [categories, setCategories] = useState();
  const [classesList, setClassesList] = useState();

  return (
    <SessionContext.Provider value={{ videoSelect, setVideoSelect, contentId, setContentId, classesList, setClassesList }}>
      {props.children}
    </SessionContext.Provider>
  );
};
const useSession = () => useContext(SessionContext);
export { useSession, SessionProvider };
