import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = ({ className }) => {
  return (
    <div className={`flex place-content-center items-center ${className} `}>
      <CircularProgress style={{ width: "70px", height: "70px" }} />
    </div>
  );
};

export default Loading;
