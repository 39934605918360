import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { Card } from "../../../shares/card/Card";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "jalali-moment";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Loading from "../../../../utils/Loading";
import { useSession } from "../../../../contexts/SessionContext";

const ClassCard = () => {
  const [classRoom, setClassRoom] = useState();
  const [category, setCategory] = useState();
  const [call, setCall] = useState();
  const { classesList, setClassesList } = useSession();
  const fetchData = useFetch({
    method: "GET",
    url: `classroom/`,
    noHeader: false,
    trigger: true,
    setter: setClassRoom,
    errMessage: () => toast.error("مشکلی در دریافت اطلاعات پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),
  });

  useEffect(() => {
    setCategory(Object.keys(classRoom ? classRoom : ""));
    console.log(Object.keys(classRoom ? classRoom : ""));
  }, [classRoom]);

  // const fetchCategory = useFetch({
  //   method: "POST",
  //   url: `classroom/section/`,
  //   noHeader: false,
  //   trigger: false,
  //   data: call,
  //   setter: setClassesList,
  // });
  
  // const formData = new FormData();
  // const submit = (x, index) => {
  //   formData.append("headline", x);
  //   setCall(formData);
  //   fetchCategory.reFetch();
    
  // };
  // console.log("resssssssss=======>", classesList);

  return (
    <>
      {fetchData.loading ? (
        <Loading />
      ) : (
        <div className="classes grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[2.3rem] pb-6 h-[82vh] overflow-y-scroll  ">
          {category?.map((x, index) => (
            <div
              style={{ cursor: "pointer" }}
              // onClick={() => {
              //   // setCall(x);
              //   submit(x, index);
              // }}
            >
              <Link to={x} state={x}>
                <Card>
                  <h1 className="text-[2.4rem] p-[7rem]">{x}</h1>
                </Card>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ClassCard;
