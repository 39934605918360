import React from "react";
import { BtnAlternative } from "../../../shares/button/Button";
import Class from "../../../../assets/img/icons/class.svg";
import Exam from "../../../../assets/img/icons/exam.svg";
import Problem from "../../../../assets/img/icons/problem-solving.svg";
import Homework from "../../../../assets/img/icons/homework.svg";
import { Card } from "../../../shares/card/Card";

const Features = () => {
    return (
        <div className=" text-white pt-32 pb-10 flex container items-center justify-around gap-28 mb-48 flex-col md:flex-row">
            <div className="w-full md:w-1/2 flex flex-col justify-center items-center md:items-start">
                <h3 className="text-[3.8rem] mb-8 text-center md:text-right">آموزشگاه ریاضی مهندس عطار</h3>
                <p className="text-[1.8rem] mb-14 text-center md:text-right px-4 md:px-0">ما در این مجموعه تلاش داریم با فراهم‏ آوری فضایی مناسب، ریاضیات کنکور سراسری را به بهترین سیمای ممکن به شما عرضه کنیم. سال‏ ها سابقۀ کار در سطح اول آموزش کنکور تجربۀ ارزشمندی به ارمغان آورده است که امید داریم آن را فرا روی شما قرار دهیم. قبولی ‏های رشته ‏های برتر علوم ریاضی و تجربی در تمام این سال‏ ها و کسب قریب به 50 رتبۀ دورقمی تنها در 4 سال اخیر، گواه خوبی بر صدق این ادعاست.

                    البته جای تأکید است که ما خود را «باعث» کسب رتبه ‏های برتر توسط دانش آموزان نمی‏دانیم؛ بلکه صرفاً تلاش می ‏کنیم فضایی را مهیا سازیم که هر دانش آموز به بیشترین رشد ممکن -دست‏ کم در درس ریاضی- برسد. تجربۀ سالیان نشان داده است که خوشبختانه در این امر ناموفق نبوده ‏ایم.
                </p>
                <BtnAlternative
                    text="ورود به سامانه"
                    className="w-fit border-2 border-white text-white px-10 py-5"
                />
            </div>
            <div className="text-black flex flex-col sm:grid grid-cols-2 grid-rows-2 gap-x-7 gap-y-6 md:-mb-80 w-full md:w-1/2 px-4 md:px-0">
                <Card className="py-[3.2rem] px-[3.2rem] text-right">
                    <img src={Class} className="mb-6" />
                    <h5 className="mb-2 text-[1.6rem] font-bold text-black">
                        کلاس‌های مجازی
                    </h5>
                    <span className="text-[1.6rem] font-light text-[#8E9BA9] leading-[2.2rem]">
                        شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت
                        مجازی بهره ببرند.
                    </span>
                </Card>
                <Card className="py-[3.2rem] px-[3.2rem] text-right font-bold">
                    <img src={Exam} className="mb-6" />
                    <h5 className="mb-2 text-[1.6rem] text-black">
                        امتحانات هفتگی
                    </h5>
                    <span className="text-[1.6rem] font-light text-[#8E9BA9]">
                        شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت
                        مجازی بهره ببرند.
                    </span>
                </Card>
                <Card className="py-[3.2rem] px-[3.2rem] text-right font-bold">
                    <img src={Problem} className="mb-6" />
                    <h5 className="mb-2 text-[1.6rem] text-black">
                        رفع اشکال رایگان
                    </h5>
                    <span className="text-[1.6rem] font-light text-[#8E9BA9]">
                        شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت
                        مجازی بهره ببرند.
                    </span>
                </Card>
                <Card className="py-[3.2rem] px-[3.2rem] text-right font-bold">
                    <img src={Homework} className="mb-6 text-[1.6rem]" />
                    <h5 className="mb-2 text-[1.6rem] text-black">
                        تکالیف هفتگی
                    </h5>
                    <span className="text-[1.6rem] font-light text-[#8E9BA9]">
                        شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت
                        مجازی بهره ببرند.
                    </span>
                </Card>
            </div>
        </div>
    );
};

export default Features;
