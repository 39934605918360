import React from "react";
import Map from "../../../../assets/img/map.png";

const Footer = () => {
    return (
        <div className="container text-white flex pt-52 md:pb-48 flex-col md:flex-row text-center gap-y-10">
            <div className="w-full md:w-1/2 ml-32">
                <h4 className="font-bold text-[4.8rem] mb-8">تماس با ما</h4>
                <p className="text-[1.8rem] font-light">
                    برای تماس با ما میتوانید از طریق آدرس info@arash-attar.org
                    پست الکترونیکی خود را ارسال کنید و یا با شماره‌های ما تماس
                    حاصل فرمایید. کارشناسان ما هروز هفته در ساعات اداری قادر به
                    پاسخگویی شما هستند.
                    <br />
                    <br />
                    برای ثبت نام در دوره های ما با کارشناس مربوطه تماس بگیرید.
                </p>
            </div>
            <div className="ml-6 flex flex-col lg:justify-between md:self-center lg:self-stretch">
                <div className=" bg-[#343A56] shadow-footerCard text-[1.6rem] flex justify-between px-12 py-10 lg:rounded-[2.4rem] gap-x-16 rounded-t-[2.4rem]">
                    <span>آموزشگاه</span>
                    <span>03136242125</span>
                </div>
                <hr className="block md:hidden mx-12" />
                <div className="bg-[#343A56] shadow-footerCard text-[1.6rem] flex flex-col px-12 py-10 lg:rounded-[2.4rem] gap-y-10 rounded-b-[2.4rem]">
                    <div className="flex justify-between">
                        <span>ثبت نام</span>
                        <span>03136242125</span>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <span>تکالیف</span>
                        <span>03136242125</span>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                        <span>آزمون‌ها</span>
                        <span>03136242125</span>
                    </div>
                </div>
            </div>
            <div className="lg:w-[26rem] h-[32.4rem] md:self-center lg:self-stretch">
                <img src={Map} className="w-full max-h-[19.6rem]" />
                <div className="bg-[#343A56] shadow-footerCard text-[1.6rem] flex flex-col pt-[2.4rem] pb-[3.2rem] px-[3.2rem] rounded-b-[2.4rem]">
                    <span>موقعیت مکانی</span>
                    <span>اصفهان،محتشم کاشانی،روبه‌روی کوچه 29،پلاک 74</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
