import React from "react";
import { BtnPrimary } from "../../../../shares/button/Button";
import { Card } from "../../../../shares/card/Card";
import moment from "jalali-moment";

const CurrentExam = ({ exam }) => {
  return (
    <Card className="grid grid-cols-10 justify-between items-center grid-rows-2 md:grid-rows-1">
      <div className="h-full w-full flex justify-center items-center md:border-l-2 border-inherit col-span-10 md:col-span-1">
        <h1 className="text-[2.4rem]">32</h1>
      </div>
      <div className="text-start col-span-4 pt-9 pb-8 pr-12">
        <h4 className="text-[1.6rem] ">
          {exam?.name}
          <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
            {moment(exam?.exam_available_time_start, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD")}
          </span>
        </h4>
        <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">{exam?.exam_headline}</p>
      </div>
      <div className="text-[1.4rem] flex flex-col sm:flex-row justify-between sm:items-center md:col-span-5 sm:pl-12 col-span-6 items-stretch gap-y-4">
        <p className="  px-[1.45rem]  "> {moment(exam?.exam_maxenterance_time, "YYYY/MM/DD").locale("fa").format(" DD MMM YYYY")}</p>

        <p className=" px-[1.45rem] border-x-2 border-inherit ">
          {moment(exam?.exam_duration, "hh:m:s").locale("fa").format("mm دقیقه ss ثانیه")} <span className="  opacity-50"></span>
        </p>

        <p className=" px-[1.45rem] ">
          {exam?.score.active_question_number} <span className="   opacity-50">سوال</span>
        </p>
        <BtnPrimary
          className="py-[1.2rem] px-[2.2rem]"
          text={"شرکت در آزمون"}
          url={`https://lab.mindescape.co/dashboard/exam/${exam.score.exam}`}
          target="_blank"
        />
      </div>
    </Card>
  );
};

export default CurrentExam;
